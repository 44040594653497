import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
	  borderBottom: `1px solid ${theme.palette.background.default}`,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gpx: {
	  color: theme.palette.primary.dark,
  }
}));

export default function Course() {
  const classes = useStyles();

  return (
	<Grid container>
		 <Grid item xs={12}>
			<Typography component="h2" variant="h4" className={classes.pageTitle} gutterBottom>
				The Yard
			</Typography>
			<Typography component="body2" color="primaryText" gutterBottom>
				The Bullshit Backyard Ultra follows the traditional Backyard format of having a day and night loop.
				The day loop will be run for the first 12 hours after the race begins before switching over to the night
				loop for the next 12 hours. The day loop is a mix of technical trails, hay fields, and pastures running
				along the Tunkhannock Creek. The night loop is on much easier terrain around the hay fields and on the
				road. The course will be marked with flags, ribbons, signage, and lights (for the night loop). Strava
				recordings and videos for the loops can be found on this page, but be aware that the course changes
				slightly year to year.<br /><br />
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="subtitle2" variant="h4" gutterBottom>
				Day Loop
			</Typography>
			<br />
			<Typography component="body1" gutterBottom>
				The daytime loop starts with <strong>Tank Alley</strong> and <strong>The Junkyard Trail</strong>.
				Enjoy the messages of encouragement spray painted on old oil tanks as you run through Tank Alley.
				The Junkyard Trail features actual junk including old cars, parts of old cars, tires, televisions,
				toilets, and more!
				<br /><br />
				After a short stint through the lower hayfield runners enter the <strong>Bamboo Forest</strong> which
				runs parallel to the Tunkhannock Creek. Every year I cut that bamboo back for the race and every year
				it grows back even thicker. This section features a stream crossing via aluminum ramp, so metal!
				After exiting the Bamboo Forest, runners detour for a loop of yet another hayfield before returning to
				the trail running along the Tunkhannock Creek.
				<br /><br />
				Next up runners take on some rock hopping to cross that pesky stream again before entering the <strong>Cow Pasture</strong>
				. The lower pasture features ankle deep holes brimming with what could be
				mud or cow shit! Runners will make their way uphill to come face to face with the herd as they munch on
				some hay bales. There's no question about the next section as it's all cow shit you're stepping in as
				you cross the primary pasture before reaching the railroad tie stream crossing.
				<br /><br />
				There's some climbing as runners make their way up to the primary grazing area for the herd. Mostly
				non-technical switchbacks across this topmost pasture before runners begin a long downhill known as <strong>Coming 'Round the Mountain</strong>. After another climb runners will traverse a short but
				very technical trail section before entering <strong>The Neighbor's Backyard Loop</strong>. Tread
				carefully here as the trail is full of unstable rocks.
				<br /><br />
				After completing the Neighbor's Backyard Loop, runners begin a downhill section where they will once
				again run along the Tunkhannock Creek all the way back through the Bamboo Forest. Another climb after
				crossing the stream into a copse of Maple and Birch. Runners cross another hayfield before they make
				their way to <strong>Red Hawk Down</strong> and loop around the pine trees. After exiting the pines,
				runners cruise across the hayfield and head back to the start/finish area.
				<br /><br />
				<Typography component="h6" variant="h6" color="textPrimary" gutterBottom>
					<strong>Strava Recordings</strong>
				</Typography>
				<ul>
					<li><Link href="https://www.strava.com/activities/8589789824/" rel="noopener noreferrer" target="_blank">2023 Day Loop</Link></li>
					<li><Link href="https://www.strava.com/activities/6839824746/" rel="noopener noreferrer" target="_blank">2022 Day Loop</Link></li>
					<li><Link href="https://www.strava.com/activities/6161671042/" rel="noopener noreferrer" target="_blank">2021 Day Loop</Link></li>
				</ul>

				<iframe width="420" height="315"
						src="https://www.youtube.com/embed/0PVav0eeQd0?autoplay=0&mute=1">
				</iframe>
				<iframe width="420" height="315"
						src="https://www.youtube.com/embed/IDxwvQAs3ww?autoplay=0&mute=1">
				</iframe>
				<br />
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="subtitle2" variant="h4" gutterBottom>
				Night Loop<br />
			</Typography>
			<br />
			<Typography component="body1" gutterBottom>
				The night loop starts with a ~1.3 mile jaunt around the hay fields before runners are rewarded with a
				roughly half mile out and back on the road. Runners then repeat the 1.3 mile hayfield loop and then
				return to the road for the final out and back before finishing back at the start/finish area.
				<br /><br />
				<i>Expect changes to the night loop in 2024.</i>
				<br /><br />
				<Typography component="h6" variant="h6" color="textPrimary" gutterBottom>
					<strong>Strava Recordings</strong>
				</Typography>
				<ul>
					<li><Link href="https://www.strava.com/activities/8765536811/" rel="noopener noreferrer" target="_blank">2023 Night Loop</Link></li>
					<li><Link href="https://www.strava.com/activities/6452325834/" rel="noopener noreferrer" target="_blank">2022 Night Loop</Link></li>
					<li><Link href="https://www.strava.com/activities/6161671042/" rel="noopener noreferrer" target="_blank">2021 Night Loop</Link></li>
				</ul>
			</Typography>
		</Grid>
	</Grid>
  );
}

			// TODO: Figure out how to enable downloads...
			//<Typography component="subtitle2" color="primaryText" gutterBottom>
			//	<Link to="/static/2021_day_course.gpx" target="_blank" download><strong>2021 Day Course GPX file</strong></Link>
			// </Typography>