import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

// Import react grid gallery
import Gallery from "react-grid-gallery";

import Medals from './static/awards/medal_load.jpg'; 
import DNFMedal from './static/awards/dnf_medal.jpg'; 
import WinnerPlaque from './static/awards/winner_plaque.jpg';

import Barnport from './static/about/Barnport.JPG';
import TrailCams from './static/about/Trail_Cam_Loop_Four.JPG';
import Mary from "./static/race_reports/Mary_post_race_2023.jpg";

export const AWARDS =
[{
        src: Medals,
		thumbnail: Medals,
        thumbnailWidth: 320,
        caption: "2021 - Custom Medals created by Ragged Cuts"
},
{
        src: DNFMedal,
		thumbnail: DNFMedal,
        thumbnailWidth: 320,
        caption: "2021 - DNF Medal created by Ragged Cuts"
},
{
        src: WinnerPlaque,
		thumbnail: WinnerPlaque,
        thumbnailWidth: 320,
        caption: "2021 - Winner plaque created by Ragged Cuts"
},
]

const useStyles = makeStyles((theme) => ({
  pageTitle: {
	  borderBottom: `1px solid ${theme.palette.background.default}`,
  },
}));

export default function About() {
  const classes = useStyles();

  return (
	<Grid container>
		<Grid item xs={12}>
			<Typography component="h2" variant="h4" className={classes.pageTitle} gutterBottom>
				Registration and Race Info
			</Typography>
			<Typography component="subtitle2" color="primaryText" gutterBottom>
				The 2024 Bullshit Backyard Ultra will take place in Lenoxville Pennsylvania on <strong>March 23rd, 2024
				</strong>.
				<br /><br />
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="body2">
				The Bullshit Backyard Ultra follows the <Link href="https://en.wikipedia.org/wiki/Backyard_ultra" rel="noopener noreferrer" target="_blank">Backyard Ultra</Link> format.
				Runners traverse a 4.167 mile loop once per hour until there is only a single runner left.
				<br /><br />
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Registration</strong>
			</Typography>
			<Typography component="body2">
				<ul>
					<li><strong>Early sign-up</strong> starting <strong>November 6th, 2023</strong> - BSBU alumni are invited to sign up early to reserve their spots.</li>
					<li><strong>Open sign-up</strong> starting <strong>December 1st, 2023</strong> - Registration opens to the public for the remaining spots.</li>
					<li><strong>Race registration is handled exclusively through Ultra Signup</strong> - <Link href="https://ultrasignup.com/register.aspx?did=97742">Click here to register via Ultra Signup</Link></li>
					<li><strong>Dropouts and Deferrals</strong> - No refunds for dropping out. Deferrals only for pregnancy and postpartum.</li>
				</ul>
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>How does it work...exactly?</strong>
			</Typography>
			<Typography component="body2">
				<ul>
					
					<li>Starting at 7:00 AM, runners will take off from the starting/finish corral on the <Link href="/course">Daytime Loop</Link>.</li>
					
					<li>Runners will have exactly 1 hour to complete the 4.167 mile loop, meaning they must cross the start/finish line in under 1 hour. Any runner failing to complete a loop in the allotted time will receive a DNF (Did not finish) and the race is over for them. </li>
					
					<li>When a runner finishes a loop they may go to their personal aid station and receive aid from their crew/themselves. They may not start the next loop until the hour has expired. </li>
					
					<li>When there are 3 minutes remaining in the hour, the race director will ring a cowbell. Again the bell will ring at 2 minutes and 1 minute remaining in the loop. </li>
					
					<li>When the hour has expired, runners intending to continue must ALREADY be in the start/finish corral. The next loop starts with the ringing of the bell as long as there is at least 1 runner in the corral. </li>
					
					<li>If only 1 runner starts a loop, on the successful completion of that loop they have won the race. If that sole runner fails to complete the loop, there is no winner and the race is over with everyone receiving a DNF. </li>
					
					<li>At 7pm the race transitions to the <Link href="/course">Night Loop</Link>, which will have easier terrain to navigate in the dark.
						<br /><strong>Mandatory gear for the Night Loop:</strong>
						<ul>
							<li>headlamp</li>
							<li>spare batteries for headlamp</li>
							<li>reflective vest or wrist/leg reflectors</li>
							<li>red blinking light to be rear-mounted</li>
							<li>cell phone</li>
						</ul>
					</li>
					<li>At 7am the next day, the race transitions back to the Daytime Loop again. </li>
					
					<li>This cycle continues for as long as the winning conditions are not met.</li>
				</ul>
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Awards and Swag</strong>
			</Typography>
			
			<Typography component="body2">
				<ul>
					<li>All runners who DNF will receive a custom wooden medal from <Link href="https://raggedcuts.com/" rel="noopener noreferrer" target="_blank">Ragged Cuts Custom Awards</Link>.</li>
					<li>The winner, if there is one, will receive a unique plaque.</li>
					<li>Runners reaching the milestone of 100 miles (or 24 loops completed) will receive a custom belt buckle to commemorate their achievement.</li>
					<li>Shirts, Hats, Hoodies, and other merch will be available for purchase through the registration process and immediately following the race.</li>
				</ul>
				Original awards and medals (2021): <br />
				<Gallery images={AWARDS} enableImageSelection={false} />
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<br />
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Aid Stations and Restrooms</strong>
			</Typography>
			<Typography component="body2">
				<ul>
					<li>Each runner will have a designated area near the start/finish line where they may set up their own aid station.</li>
						<ul>
							<li>There will be enough room for runners to set up tents/chairs/tables/etc.</li>
							<li>Runners who have dropped out are asked to give up their spots closer to the start/finish line to allow those remaining runners to have more desirable locations. </li>
						</ul>
					<li>Two port-o-potties will be located just steps away from the starting corral.</li>
						<ul>
							<li>Typical restroom supplies will be available including menstrual products.</li>
							<li>Active runners take priority in the port-o-potty queue.</li>
						</ul>
					<li>There will also be common space with tables set up inside of a large barn port which is protected from rain.</li>
						<ul>
							<li>Bring your own chair to pull up at a table or bring your own table! We'll fit as many folks as we can in here, but it may be tight!</li>
							<li>See the barn port behind the runners in this picture: <br /><img src={Barnport} width="300px" /> <br /></li>
						</ul>
					<li>There will be community water and food provided at the barn port.</li>
						<ul>
							<li>The menu for the food will be confirmed a couple weeks before the race, in the past we've had: mashed potatoes, chicken broth, and pot roast.</li>
							<li>There will be no special requests accepted for food, runners are expected to bring enough fuel for themselves and their crew. There are multiple local options for pizza, hoagies, etc., but they require pickup in person.
								Crews are encouraged to coordinate with other runner's crews to pool orders and pickup with 1 car.</li>
						</ul>
				</ul>
			</Typography>
		</Grid>
		<Grid item xs>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Crew</strong>
			</Typography>
			<Typography component="body2">
				<ul>
					<li>Pacing is not permitted.</li>
					<li>All crew members must fill out a waiver. Crew members are considered anyone who traveled to the race with you.</li>
					<li>Runners and their crew will be limited to 1 vehicle.</li>
					<li>No dogs allowed, not even the extremely well behaved.</li>
					<li>Crew and DNF'd runners can help any runners during the "Interloopal" phase (after finishing a loop and before the next loop starts).</li>
					<li>Crew are expected to follow the same rules as runners or risk banishment.</li>
				</ul>
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Additional Rules, Info and Etiquette</strong>
			</Typography>
			<Typography component="body2">
				<ul>
					<li>Don't be a jerk, treat everyone with respect, and take care of your fellow runners while on the course.</li>
					<li>If you have decided to drop out of the race you MUST inform the race director/volunteers as soon as possible.</li>
					<li>There will be <strong>Livestock</strong> (a few cows, a steer, a horse, and a donkey), on the course.
						If they are obstructing part of the trail, runners shall use their best judgement to avoid coming into close contact.
						Runners shall not attempt to pet, take a selfie, or otherwise agitate the livestock.</li>
					<li>Aid shall NOT be received from crew after a runner has started a loop. Note: Active runners can give each other aid while running a loop as long as they are carrying it with them before the loop starts.</li>
					<li>All runners must run the course as marked, any significant and/or repetitive deviations will result in disqualification.</li>
					<li>Headphone listening is not allowed on the day loop.</li>
					<li>We will be recording and streaming race video around the starting corral area.</li>
					<li>Our photographers will try to get your picture in interesting places around the course and at the end of your race.
						Trail cameras will also take pictures of runners every loop and yes, you'll know where they are.
						Photos will be shared as soon as they are ready post-race.
						<br /><img src={TrailCams} width="300px" /> <br />
					</li>
				</ul>
			</Typography>
		</Grid>
		<Grid item xs>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Accommodations</strong>
			</Typography>
			<Typography component="body2">
				Weather permitting, there will be plenty of space for tent camping. For RV set ups please reach out to discuss logistics. <br /><br />
				Local accommodations:
				<ul>
					<li><a href="https://hotelanthracite.com/">Hotel Anthracite</a> (12 miles, 20 minutes away)</li>
					<li><a href="https://www.choicehotels.com/pennsylvania/clarks-summit/econo-lodge-hotels/pa482">Econo Lodge, Clarks Summit</a> (16 miles, 17 minutes away)</li>
					<li><a href="https://www.hilton.com/en/hotels/avpdcht-home2-suites-dickson-city-scranton/">Hilton, Dickson City</a> (20 miles, 22 minutes away)</li>
				</ul>
			</Typography>
		</Grid>
		
		<Grid item xs={12}>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Parking</strong>
			</Typography> 
			<Typography component="body2">
				<ul>
					<li>Vehicles will be parked within walking distance of the starting corral.</li>
					<li>One vehicle per runner. Please carpool if you are able.</li>
				</ul>
			</Typography>
		</Grid>
		
		<Grid item xs={12}>
			<Typography component="h5" variant="subtitle1" color="primaryText" gutterBottom>
				<strong>Weather</strong>
			</Typography>
			<Typography component="body2">
				<ul>
					<li>March weather is unpredictable. If conditions render the race unsafe to run, we will opt for a back-up date of <strong>March 30th</strong>.</li>
					<li>The race can be paused due to severe weather at the discretion of the race director. If this occurs we will attempt to wait out the severe weather and then resume when it is safe.</li>
				</ul>
			</Typography>
		</Grid>

	</Grid>
  );
}